import React from "react";

import NavigationBarLink from "./navigationbar.link";
import NavigationBarSubMenu from "./navigationbar.submenu";
import Link from "src/components/link";
import Icon from "src/components/icon";

import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    collapse_first_level_items: boolean;

    onClick?: () => void;
    onDimension?: (width: number) => void;
}

type State = {
    isCollapsed: boolean;
}

export default class NavigationBarSubMenuWithLink extends React.Component<Props, State> {
    state = {
        isCollapsed: true
    }

    private linkRef = React.createRef<HTMLElement>();

    public get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label') as string;
    }

    private get link(): string {
        return this.props.item.link_type === 'external'
            ? getLanguageField(this.props.item.link_translations, this.props.language, 'link_href') as string
            : getSlug(this.props.language, this.props.defaultLanguage, this.props.item.link_page?.translations || []);
    }

    public hasColor() {
        if (this.props.item.item_color) return true;
        return false;
    }

    private get colors() {
        if (this.props.depth >= 2 && !this.hasColor()) return "text-[color:var(--link-subitems-text-color)] hover:text-[color:var(--dropdown-hover-color)]";
        if (this.props.depth === 1 && !this.hasColor()) return "text-[color:var(--text-color)] hover:text-[color:var(--dropdown-hover-color)]";
        return "text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]";
    }

    private get textClass() {
        if (this.props.depth >= 2) return "navBarSubItems";
        if (this.props.depth === 1) return "navBarItems";
        return "navBarText";
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    public getChevron = () => {
        if (!this.props.collapse_first_level_items || !this.items.length) return null;
        return <Icon style={this.style} className={`${this.colors} ml-1 cursor-pointer`} icon={this.state.isCollapsed ? "ChevronDown" : "ChevronUp"} onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })} />
    }

    render(): React.ReactNode {
        const {label, link, textClass, colors, getChevron, props} = this;
        const {language, defaultLanguage, collapse_first_level_items} = props;

        return <>
            <div className="navBarText navigation-bar-submenu-label text-[color:var(--text-color)] flex items-center">
                <Link style={this.style} linkRef={this.linkRef} title={label} href={link} onClick={this.props.onClick} className={`${textClass} navigation-bar-link navigation-item-depth-${this.props.depth} ${colors}`}>
                    <span className="truncate">{label}</span>
                </Link>
                {getChevron()}
            </div>
            {this.state.isCollapsed && collapse_first_level_items ? null : (this.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <NavigationBarLink onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <NavigationBarSubMenu collapse_first_level_items={collapse_first_level_items} onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <NavigationBarSubMenuWithLink collapse_first_level_items={collapse_first_level_items} onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menulink_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}